import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  StationSelection,
  InputData,
  VerifiedInputData,
  ChangePassengerSeat,
  SelectStationPriceCard,
  Loader,
  MaintenancePage,
} from "./components/index";
import { validateEnrollmentId } from "./api/ApiUtils";
import RedirectPage from "./components/CommonComponents/RedirectPage/RedirectPage";
import { PopUpModal } from "./components/CommonComponents/PopUp/PopUpModal";

function App() {
  const [state, setState] = useState({ isEnrollmentIdValid: false });
  const [errorPopUp, setErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    localStorage.clear();
    async function validationCallback() {
      try {
        let KAI = JSON.parse(localStorage.getItem("KAI"));
        if (KAI && Object.keys(KAI).length)
          localStorage.setItem("KAI", JSON.stringify(KAI));
        else localStorage.setItem("KAI", JSON.stringify({}));

        let enrollmentId = window.location.search.split("=")[1] || "";

        if (enrollmentId === "")
          enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

        if (enrollmentId != null) {
          localStorage.setItem("enrollmentId", JSON.stringify(enrollmentId));
          setLoading(true);

          const res = await validateEnrollmentId(enrollmentId);
          if (res.success) {
            setState({ isEnrollmentIdValid: true });
            setLoading(false);
          } else {
            setState({ isEnrollmentIdValid: false, errorMessage: res.message });
            setErrorPopUp({ showPopUp: true });
            setLoading(false);
          }
        }
      } catch (e) {
        setState({
          isEnrollmentIdValid: false,
          errorMessage: "Something went wrong! Please try again.",
        });
        setErrorPopUp({ showPopUp: true });
      }
    }
    validationCallback()
  }, []);

  const handlePopUpClose = () => {
    setErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  const featureFlag = JSON.parse(process.env.REACT_APP_FEATURE || '{}')
  useEffect(() => {
    if(featureFlag?.appMaintainance) {
      setIsMaintenance(true)
    }
    else {
      setIsMaintenance(false)
    }
  }, [featureFlag]);
  console.log({isMaintenance})

  if (!state.isEnrollmentIdValid) {
    return (
      <div
        id="APP"
        style={{
          background: "#f2f2f2",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <PopUpModal
          open={errorPopUp.showPopUp}
          message={state.errorMessage}
          error={true}
          handlePopUpClose={handlePopUpClose}
        />
        <Loader loading={loading} />
      </div>
    );
  }

  return (
    <div id="APP" className="App">
      <Loader loading={loading} />
      <Router>
        <Routes>
          <Route 
            exact 
            path="/" 
            element={isMaintenance ? <MaintenancePage /> : <StationSelection />} 
          />
          <Route 
            exact 
            path="/inputdata" 
            element={isMaintenance ? <MaintenancePage /> : <InputData />} 
          />
          <Route
            exact
            path="/stationpricecard"
            element={isMaintenance ? <MaintenancePage /> : <SelectStationPriceCard />}
          />
          <Route
            exact
            path="/verefiedinputdata"
            element={isMaintenance ? <MaintenancePage /> : <VerifiedInputData />}
          />
          <Route 
            exact 
            path="/pilihkursi" 
            element={isMaintenance ? <MaintenancePage /> : <ChangePassengerSeat />} 
          />
          <Route 
            exact 
            path="/redirect" 
            element={isMaintenance ? <MaintenancePage /> : <RedirectPage />} 
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
