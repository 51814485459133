import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { getWagonClass } from "../../common/Utils";
import PropTypes from "prop-types";

const PassengerDetailCards = ({ seatChange }) => {
  const [passengers, setPassengers] = useState([]);
  const [passengersList, setPassengersList] = useState([]);
  const KAI = JSON.parse(localStorage.getItem("KAI"));

  useEffect(() => {
    setPassengers([KAI.seatList.currentSeat]);
    setPassengersList(KAI.seatList.paxes);
    // eslint-disable-next-line
  }, []);

  const getIndex = (idnumber) => {
    for (let index = 0; index < passengersList.length; index++) {
      if (passengersList[index].idnumber === idnumber) {
        return index;
      }
    }
  };

  return (
    <div className={"changeSeatPassengerDetailCard-container"}>
      {passengers.map(
        (
          {
            idnumber,
            name,
            wagonclasscode,
            stamformdetcode,
            wagondetrow,
            wagondetcol,
            passengertype,
          },
          i
        ) => {
          const index = getIndex(idnumber) + 1;
          return (
            passengertype === "A" && (
              <div style={{ padding: 16 }} key={`passengerCard-container-${idnumber}`} id={`passengerCard-${i}`}>
                <div
                  className={`${"displayFlex"} ${"cardStyle"}`}
                  id="passenger-card"
                  style={{
                    border: seatChange ? "2px solid #2196f3" : "1px solid #DDD",
                  }}
                >
                  <Typography className={"passengerIndex"}>{index}</Typography>
                  <div style={{ marginLeft: "12px" }}>
                    <div className={"displayFlex"}>
                      <Typography
                        id={`passengerName-${i}`}
                        className={"passengerCardPrimaryInfo"}
                      >{name}</Typography>
                    </div>
                    <Typography
                      id={`passengerClass-${i}`}
                      className={"passengerCardSecondaryInfo"}
                    >{`${getWagonClass(
                      stamformdetcode.split("-")[0],
                      stamformdetcode.split("-")[1]
                    )}`}</Typography>
                    <Typography
                      id={`passengerSeat-${i}`}
                      className={"passengerCardPrimaryInfoBlue"}
                    >{`${wagondetrow}${wagondetcol}`}</Typography>
                  </div>
                  <CheckCircleRoundedIcon
                    style={{
                      height: "1rem",
                      marginLeft: "10px",
                      color: "#1F8FE5",
                      alignSelf: "flex-start",
                      visibility: seatChange ? "" : "hidden",
                    }}
                  />
                </div>
              </div>
            )
          );
        }
      )}
    </div>
  );
};

PassengerDetailCards.propTypes = {
  seatChange: PropTypes.bool,
};

export default PassengerDetailCards;
