import React, { useState } from "react";
import { Typography } from "@mui/material";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";
import PropTypes from "prop-types";

const Header = ({ header }) => {
  const [errorPopUp, setErrorPopUp] = useState({ showPopUp: false });
  const handlePopUpClose = () => {
    setErrorPopUp({ showPopUp: false });
  };

  return (
    <div id="header" className="header">
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <Typography id="headerText" className="headerText">{header}</Typography>
    </div>
  );
};

Header.propTypes = {
  header: PropTypes.string
};

export default Header;
