import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

export const PopUpModal = ({ open, message, error, handlePopUpClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handlePopUpClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-title">{error ? "OOPS!" : ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || "Something went wrong! Please try again."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePopUpClose}
          autoFocus
          className={`themeButton themeButtonContained`}
          variant={"contained"}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PopUpModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  error: PropTypes.bool,
  handlePopUpClose: PropTypes.func
};
