import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const Kembali = ({ back }) => {
  const navigation = useNavigate();
  return (
    <Button
      id={'returnButon'}
      className={"themeButton themeButtonWhite"}
      variant={"filled"}
      fullWidth
      style={{ margin: "10px 0" }}
      onClick={() => navigation(back)}
    >{"Kembali"}</Button>
  );
};

Kembali.propTypes = {
  back: PropTypes.string,
};

export default Kembali;
