import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const Loader = ({ loading }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(loading);
  }, [loading]);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool,
}

export default Loader;
