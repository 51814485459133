import React, { useEffect, useState } from "react";
import "../Styles/InputData.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import TrainIcon from "@mui/icons-material/Train";
import {
  Header,
  Footer,
  ContactDetails,
  PassengerInfo,
  Loader,
  Kembali,
} from "../index";
import { bookTicketApi } from "../../api/ApiUtils";
import { getWagonClass, priceFormatter } from "../../common/Utils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";
import PropTypes from "prop-types";

const InputData = () => {
  const header = "Isi Data";
  const navigate = useNavigate();
  // CHECKBOX STATE for Contact Person as User
  const [disableFirstName, setDisableFirstName] = useState(false);
  const [stationTiciketCard, setStationTiciketCard] = useState({});
  const [errorPopUp, setErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));
    if (!KAI?.stationDetails || !KAI?.ticketDetails) navigate("/");
    else {
      const { stationDetails, ticketDetails } = KAI;
      let stationTiciketCard = {
        tripdate: ticketDetails.tripdate,
        departdate: ticketDetails.departdate,
        originStation: stationDetails.sourceStation.stationname,
        destinationStation: stationDetails.destnationStation.stationname,

        departureTime: ticketDetails.departuretime,
        arrivalTime: ticketDetails.arrivaltime,

        trainName: ticketDetails.trainname,
        wagonClass: ticketDetails.wagonclasscode,
        subClass: ticketDetails.subclass,

        adultPassengers: stationDetails.adultPassengers,
        childPassengers: stationDetails.childPassengers,
        totalPassengers:
          parseInt(stationDetails.adultPassengers) +
          parseInt(stationDetails.childPassengers),
        totalAmount:
          parseInt(stationDetails.adultPassengers) *
          parseInt(ticketDetails.fares[0].amount),
        trainNumber: KAI.noka,
      };
      setStationTiciketCard(stationTiciketCard);
    }
  }, [navigate]);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  const handlePesanSubmit = () => {
    const p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
    p.then(async (response) => {
      if (response?.passengerList && response?.customerContactDetails) {
        let {
          passengerList,
          customerContactDetails,
          ticketDetails,
          stationDetails,
        } = response;
        // check for customerContactDetails contains all the data.
        let { name, phoneCode, phoneNo, email } = customerContactDetails;
        let customerContactDetailsCheck =
          name !== "" && phoneCode !== "" && phoneNo !== "" && email !== "";

        // min no.of passenger is 1 so there will be always atleast 1 passenger.
        // check for passengerList contains all the data.
        let passengerListCheck = true;

        for (let i = 0; i < passengerList.length; i++) {
          passengerListCheck =
            passengerList[i].name !== "" &&
            ((passengerList[i].passengerID === "Paspor" &&
              passengerList[i].numberID.length === 8) ||
              (passengerList[i].passengerID === "KTP" &&
                passengerList[i].numberID.length === 16)) &&
            (passengerList[i].passenger === "adult" ||
              (passengerList[i].passenger === "child" &&
                moment(stationTiciketCard.departdate, "YYYY-MM-DD").diff(
                  moment(passengerList[i].childBirthDate).subtract(1, "days"),
                  "years"
                ) < 3));
          if (!passengerListCheck) break;
        }

        if (passengerListCheck && customerContactDetailsCheck) {
          // ("all passed!");
          setError(false);
          let paxes = passengerList.map((item) => {
            let passenger = {
              idNumber: item.numberID,
              name: item.name,
              passengerType: item.passenger === "adult" ? "A" : "I",
              idNumType: item.passengerID === "KTP" ? 1 : 4,
            };
            if (item.passenger === "child") {
              passenger.dob = moment(item.childBirthDate).format("YYYY-MM-DD");
            }
            return passenger;
          });

          let request = {
            propScheduleId: ticketDetails.propscheduleid,
            tripId: ticketDetails.tripid,
            originId: ticketDetails.originid,
            destinationId: ticketDetails.destinationid,
            stasiunOrginCode: ticketDetails.stasiunorgcode,
            stasiunDestCode: ticketDetails.stasiundestcode,
            tripDate: ticketDetails.tripdate,
            departDate: ticketDetails.departdate,
            noka: ticketDetails.noka,
            wagonClassCode: ticketDetails.wagonclasscode,
            wagonClassId: ticketDetails.wagonclassid,
            customerName: name,
            phone: phoneNo,
            email: email,
            subClass: ticketDetails.subclass,
            ticketType: "R",
            totPsgAdult: stationDetails.adultPassengers,
            totPsgChild: 0,
            totPsgInfant: stationDetails.childPassengers,
            paxes: paxes,
          };
          setLoading(true);
          let response = await bookTicketApi(request);
          if (response.success) {
            const KAI = JSON.parse(localStorage.getItem("KAI"));
            KAI.bookingcode = response.data.bookingcode;
            localStorage.setItem("KAI", JSON.stringify(KAI));
            setLoading(false);
            navigate("/verefiedinputdata");
          } else {
            setLoading(false);
            setErrorPopUp({
              ...errorPopUp,
              showPopUp: true,
              message: response.message,
            });
          }
        } else {
          // ("some entries are empty!");
          setError(true);
        }
      } else {
        // ("some entries are empty!");
        setError(true);
      }
    });
  };
  const handlePopUpClose = () => {
    setErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div id={"InputData"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <Header header={header} back={"/stationpricecard"} step={"3"} />
      <div id={"InputDataForm"} style={{ padding: "16px" }}>
        <StationTicketCard stationTiciketCard={stationTiciketCard} />

        {/* CONTACT FORM */}
        <ContactDetails
          setDisableFirstName={setDisableFirstName}
          submitError={error}
        />

        {/* PASSENGER FROM */}
        <PassengerInfo
          disableFirstName={disableFirstName}
          ticiketInfo={stationTiciketCard}
          submitError={error}
        />

        <PricingDetails stationTiciketCard={stationTiciketCard} />

        <Button
          id={"Pesan"}
          fullWidth
          variant={"contained"}
          className={`themeButton themeButtonContained`}
          onClick={() => handlePesanSubmit()}
        >{"Pesan"}</Button>
        <Kembali back={"/stationpricecard"} />
      </div>
      <Footer />
    </div>
  );
};
export default InputData;

const StationTicketCard = ({ stationTiciketCard }) => (
  <Paper
    id={"selectedStationTicketCard"}
    className={"paperCards"}
    style={{ padding: 0 }}
  >
    {/* HEADER ICON AND TEXT */}
    <div
      id={"selectedStationTicketCard-date"}
      className={"selectedTicketCard-header"}
    >
      <TrainIcon className={"selectedTicketCard-headerIcon"} />
      <Typography
        id={"selectedStationTicketCard-date-value"}
        className={"selectedTicketCard-headerText"}
      >{moment(stationTiciketCard.departdate, "YYYY-MM-DD").format(
        "ddd, DD MMM YYYY"
      )}</Typography>
    </div>

    <div style={{ margin: "8px 0", padding: "0 16px" }}>
      {/* STATION NAME */}
      <div
        id={"selectedStationTicketCard-name"}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Typography
          id={"selectedStationTicketCard-name-start"}
          className={"selectedTicketCard-station"}
        >{stationTiciketCard.originStation}</Typography>
        <ArrowForwardRoundedIcon
          fontSize="18"
          style={{ margin: "0 5px" }}
          className={"iconArrowInSelectInput"}
        />
        <Typography
          id={"selectedStationTicketCard-name-end"}
          className={"selectedTicketCard-station"}
        >{stationTiciketCard.destinationStation}</Typography>
      </div>
      {/* TIME */}
      <div
        id={"selectedStationTicketCard-time"}
        style={{ display: "flex", alignItems: "center", marginTop: 4 }}
      >
        <Typography
          id={"selectedStationTicketCard-time-start"}
          className={"selectedTicketCard-time"}
        >{moment(stationTiciketCard.departureTime, "HHm").format(
          "HH:mm"
        )}</Typography>
        <Divider
          style={{ width: "1vw", margin: "0 5px", background: "#333" }}
        />
        <Typography
          id={"selectedStationTicketCard-time-end"}
          className={"selectedTicketCard-time"}
        >{moment(stationTiciketCard.arrivalTime, "HHm").format(
          "HH:mm"
        )}</Typography>
      </div>
    </div>
    {/* TRAIN CLASS */}
    <div
      id={"selectedStationTicketCard-class"}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 16px 16px 16px",
      }}
    >
      <Typography
        id={"selectedStationTicketCard-class-start"}
        className={"selectedTicketCard-trainClass"}
      >{
        stationTiciketCard.trainName +
        " (" +
        stationTiciketCard.trainNumber +
        ")"
      }</Typography>
      <Divider style={{ width: "1vw", margin: "0 5px", background: "#333" }} />
      <Typography
        id={"selectedStationTicketCard-class-end"}
        className={"selectedTicketCard-trainClass"}
      >{getWagonClass(
        stationTiciketCard.wagonClass,
        stationTiciketCard.subClass
      )}</Typography>
    </div>
  </Paper>
);


StationTicketCard.propTypes = {
  stationTiciketCard: PropTypes.object,
};


const PricingDetails = ({ stationTiciketCard }) => {
  let totalAmount = priceFormatter(stationTiciketCard.totalAmount || 0);
  const [open, setOpen] = useState(false);
  return (
    <div style={{ margin: "24px 0" }}>
      <Typography className={"inputSectionLabels"}>{"Rincian Harga"}</Typography>
      <div style={{ marginTop: "16px" }}>
        <Accordion
          id={"RincianHarga-dropdown"}
          className={"dropShadow"}
          style={{ padding: "4px 16px" }}
          onChange={() => setOpen(!open)}
        >
          <AccordionSummary style={{ padding: 0 }}>
            <div style={{ width: "100%" }} className={"displayFlex"}>
              <div className={"displayFlex"}>
                {open ? (
                  <KeyboardArrowUpRoundedIcon
                    style={{ marginRight: "12px" }}
                    className={"iconArrowInSelectInput"}
                  />
                ) : (
                  <KeyboardArrowDownRoundedIcon
                    style={{ marginRight: "12px" }}
                    className={"iconArrowInSelectInput"}
                  />
                )}
                <Typography
                  id={"Harga-Yang-Anda-Bayar"}
                  className={"priceCard-header"}
                >{"Harga yang Anda bayar"}</Typography>
              </div>
              <Typography
                id={"Harga-Yang-Anda-Bayar-value"}
                className={"priceCard-headerPrice"}
              >{`Rp ${totalAmount}`}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{ padding: "16px 0", borderTop: "2px dotted #DDD" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className={"displayFlex"}>
                <Typography
                  id="Argo-Dewasa"
                  className={"priceCard-detailText"}
                >{`Argo (Dewasa) x ${stationTiciketCard.adultPassengers}`}</Typography>
                <Typography
                  id="Argo-Rp"
                  className={"priceCard-detailPrice"}
                >{`Rp ${totalAmount}`}</Typography>
              </Grid>
              <Grid item xs={12} className={"displayFlex"}>
                <Typography
                  id="Argo-Bayi"
                  className={"priceCard-detailText"}
                >{`Argo (Bayi) x ${stationTiciketCard.childPassengers}`}</Typography>
                <Typography
                  id="GRATIS"
                  className={"priceCard-detailPrice"}
                  style={{ color: "#0AB970" }}
                >{"GRATIS"}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};


PricingDetails.propTypes = {
  stationTiciketCard: PropTypes.object,
};
