import React, { useEffect, useState } from "react";
import { IconButton, InputAdornment, Slide, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { TextView } from "../../common/Components";
import PropTypes from "prop-types";

const SelectStationDropDown = ({
  list,
  slideOpen,
  setOpenStationDropDown,
  setStationName,
  title,
}) => {
  const [name, setName] = useState("");
  const [stationArray, setStationArray] = useState([]);
  const [selectedStation, setSelectedStation] = useState();

  useEffect(() => {
    if (name !== "") {
      let sortedStationList = [];
      for (let i = 0; i < list.length; i++) {
        let isStationName = list[i].stationname
          .toLowerCase()
          .includes(name.toLocaleLowerCase());
        let isStationCode = list[i].stationcode
          .toLowerCase()
          .includes(name.toLocaleLowerCase());
        if (isStationName || isStationCode) {
          sortedStationList.push(list[i]);
        }
      }
      setStationArray([...sortedStationList]);
    }
    if (name === "") setStationArray([...list]);
  }, [name, list]);

  // store all station names namesArray.
  useEffect(() => {
    setStationArray([...list]);
  }, [list]);

  useEffect(() => {
    if (selectedStation) {
      setStationName(selectedStation);
      setOpenStationDropDown(false);
    }
  }, [selectedStation, setStationName, setOpenStationDropDown]);

  return (
    <div
      className={"selectStationList-container"}
      style={{ display: slideOpen ? "" : "none" }}
    >
      <Slide
        direction={"up"}
        in={slideOpen}
        mountOnEnter
        unmountOnExit
        className={"selectStationList-area"}
        id="slider-container"
      >
        <div
          className={"selectStationList-area"}
          style={{ display: slideOpen ? "" : "none" }}
        >
          <div className={"sliderStationList"} id="slider-container">
            <div id="slider-paper" style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                {/* HEADER NAME */}
                <div className={"selectStationSliderHeader"}>
                  <Typography
                    className="headerText"
                    color={"#333"}
                    id="headerText"
                  >{title}</Typography>
                  <IconButton
                    onClick={() => {
                      setName("");
                      setOpenStationDropDown(false);
                    }}
                  >
                    {" "}
                    <CloseIcon style={{ color: "#333" }} />{" "}
                  </IconButton>
                </div>
                {/* INPUT BOX */}
                <div style={{ padding: "0 16px" }}>
                  <TextView
                    id="station-name-input"
                    fullWidth
                    placeholder="Cari kota atau stasiun"
                    onChange={(e) => setName(e.target.value)}
                    autoComplete={"off"}
                    value={name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "#00529C" }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="filled"
                  />
                </div>

                <div className={"stationlistContainer"}>
                  <div>
                    {stationArray.map(
                      ({ stationname, title, stationcode, id }, i) => (
                        <div
                          key={id}
                          className={"stationNameBlock"}
                          onClick={() => {
                            setSelectedStation(stationArray[i]);
                          }}
                        >
                          <div>
                            <Typography
                              className={"stationName"}
                            >{stationname}</Typography>
                            <Typography
                              style={{ fontSize: "12px", color: "#333" }}
                            >{title}</Typography>
                          </div>
                          <Typography
                            className={"stationStatus"}
                          >{stationcode}</Typography>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  );
};

SelectStationDropDown.propTypes = {
  list: PropTypes.array,
  slideOpen: PropTypes.bool,
  setOpenStationDropDown: PropTypes.func,
  setStationName: PropTypes.func,
  title: PropTypes.string,
};

export default SelectStationDropDown;
